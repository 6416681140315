<template>
  <div>
    <span v-if="small">
      Total:
    </span>
    <b>{{ totalAVista | currency }}</b>
    <span v-if="small"> ou {{ totalAPrazo | currency }} a prazo</span>
    <div class="body-2" v-if="!small">
      {{ totalAPrazo | currency }} a prazo
    </div>
  </div>
</template>

<script>
import currency from 'currency.js';

export default {
  props: {
    ofertaCarrinho: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    totalAVista() {
      return currency(this.ofertaCarrinho.precoProdutoAVista, { precision: 4 })
        .multiply(this.ofertaCarrinho.quantidade)
        .add(this.ofertaCarrinho.impostoTotalAVista)
        .value;
    },
    totalAPrazo() {
      return currency(this.ofertaCarrinho.precoProdutoAPrazo, { precision: 4 })
        .multiply(this.ofertaCarrinho.quantidade)
        .add(this.ofertaCarrinho.impostoTotalAPrazo)
        .value;
    },
  },
};
</script>
