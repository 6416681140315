<template>
  <v-row dense class="title-2">
    <v-col cols="12" sm="5" class="font-weight-bold">
      Total
    </v-col>
    <v-col cols="12" sm="7" class="text-right">
      <span class="font-weight-bold">
        {{ item.totalCompraAVista | currency }} à vista
      </span>
      <div class="body-1">
        {{ item.totalCompraAPrazo | currency }} a prazo
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
