<template>
  <v-menu
    :close-on-content-click="false"
    v-model="menuOpened"
    :nudge-width="300"
    max-width="500"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <span class="caption text--primary--color prazos-gerais-text">
        <a v-on="on">
          <span class="font-weight-bold">Clique aqui</span>
          para ver todos os prazos disponíveis para este fornecedor
        </a>
      </span>
    </template>
    <v-card>
      <v-card-title class="title font-weight-bold">Prazos disponíveis</v-card-title>
      <v-card-text class="text--primary">
        <div v-if="loading">
          <px-circular-loading/>
        </div>
        <div v-if="!loading && (!prazos || !prazos.length)">
          Não há mais prazos disponíves para este fornecedor
        </div>
        <v-list dense v-if="!loading && prazos && prazos.length">
          <template v-for="(prazo, index) in prazos">
            <v-list-item :key="prazo.id">
              {{ prazo.prazoPagamento.descricao }}
              dias a partir de
              <span class="font-weight-bold">&nbsp;{{ prazo.valorMinimo | currency }}</span>
            </v-list-item>
            <v-divider :key="`div-${prazo.id}`" v-if="index !== (prazos.length - 1)"/>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import PrazoPagamentoService from '@/api/prazo-pagamento-service';

export default {
  props: {
    configuracaoId: {
      type: [Number, String],
      required: true,
    },
    valorAPrazo: Number,
  },
  data() {
    return {
      menuOpened: false,
      loading: false,
      prazos: null,
    };
  },
  methods: {
    getPrazos() {
      this.loading = true;
      PrazoPagamentoService.getPrazosMarketplacePageCompleto(this.configuracaoId, {
        limit: 20,
        offset: 0,
        query: 'order by valorMinimo',
      })
        .then(({ data }) => {
          this.prazos = data.content;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getPrazos();
  },
};
</script>

<style scoped>
.prazos-gerais-text {
  text-align: start;
}
</style>
