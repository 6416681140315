<template>
  <div>
    <v-radio-group v-model="localValue">
      <v-radio v-for="(prazo, index) in options" :key="index" :value="prazo">
        <template v-slot:label>
          <div class="text--primary">
            {{ prazo.description }}
          </div>
        </template>
      </v-radio>
    </v-radio-group>
  </div>
</template>

<script>
import { FormaPagamento } from 'px-business-components';

export default {
  props: {
    value: Object,
  },
  data() {
    return {
      selected: null,
      options: [
        {
          formaPagamento: FormaPagamento.keys.A_VISTA.key,
          description: 'À vista',
        },
        {
          formaPagamento: FormaPagamento.keys.A_PRAZO.key,
          description: 'A prazo (a combinar)',
        },
      ],
    };
  },
  computed: {
    localValue: {
      get() { return this.value; },
      set(localValue) {
        this.$emit('input', localValue);
      },
    },
  },
};
</script>
