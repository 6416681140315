<template>
  <v-row :class="textClass">
    <v-col cols="12" md="4" class="d-flex align-center">
      <template v-if="freteCep.picture">
        <v-img class="logo-transportadora"
               :src="freteCep.picture"/>
      </template>
      <template v-else>
        {{ optionDescription }}
      </template>
      <template v-if="freteCep.name">
        <span class="ml-3 body-2">({{ freteCep.name }})</span>
      </template>
    </v-col>
    <v-col cols="12" md="8" class="text-md-right mt-3 mt-md-0">
      <div class="body-2">
        <div class="font-weight-bold">
          {{ freteCep.valorFrete | currency }}
        </div>
        <div>
          entrega em {{ freteCep.prazoEmbarque }} dias
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    freteCep: {
      type: Object,
      required: true,
    },
    textClass: {
      type: String,
      default: '',
    },
    showDetails: {
      type: Boolean,
      default: false,
    },
    fornecedor: {
      type: Object,
      required: true,
    },
  },
  computed: {
    optionDescription() {
      return this.freteCep.modoFrete === 'ENTREGUE_FORNECEDOR'
        ? 'Entregue pelo fornecedor' : 'Retirar no fornecedor';
    },
  },
};
</script>
