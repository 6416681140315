<template>
  <div>
    <px-card-color class="mx-8" color-class="border-right-success"
                   v-if="freteOriginal.ofertaFreteGratis && showComponent">
      <template v-slot:icon>
        <v-icon size="48">mdi-truck-outline</v-icon>
      </template>
      <div class="caption">
        Este fornecedor oferece frete grátis a partir de
        <b>
          {{ freteOriginal.taxaMinimaEntrega | currency }}
        </b>
      </div>
      <div class="mt-2">
        <v-progress-linear color="success" height="8" rounded :value="percentual"/>
      </div>
      <div class="mt-2 caption">
        Adicione mais {{ diferenca | currency }}
        <router-link :to="`/fornecedor/${fornecedor.id}`" target="_blank">
        deste fornecedor</router-link>
        para <b>frete grátis</b>
      </div>
    </px-card-color>
  </div>
</template>

<script>
import currency from 'currency.js';

export default {
  props: {
    fornecedor: {
      type: Object,
      required: true,
    },
    freteOriginal: {
      type: [Object],
      required: true,
    },
    totalProdutosAVista: {
      type: [Number],
      required: true,
    },
    totalProdutosAPrazo: {
      type: [Number],
      required: true,
    },
  },
  computed: {
    total() {
      if (this.totalProdutosAPrazo > this.totalProdutosAVista) {
        return this.totalProdutosAPrazo;
      }
      return this.totalProdutosAVista;
    },
    showComponent() {
      return this.total < this.freteOriginal.taxaMinimaEntrega;
    },
    percentual() {
      return currency(100)
        .multiply(this.total)
        .divide(this.freteOriginal.taxaMinimaEntrega)
        .value;
    },
    diferenca() {
      return currency(this.freteOriginal.taxaMinimaEntrega)
        .subtract(this.total)
        .value;
    },
  },
};
</script>
